<template>
  <UserMaintenance />
</template>

<script>
import UserMaintenance from '@/components/pages/maintenance/UserMaintenance'

export default {
  components: {
    UserMaintenance
  }
}
</script>
