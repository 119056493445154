<template>
  <Modal
    header="Reset Password"
    okLabel="Reset Password"
    :show="show"
    :loadingSubmit="loading"
    @close="close"
    @submit="submit"
  >
    <q-form ref="userResetForm">
      <q-card flat>
        <q-card-section>
          <p>Are you sure you want to reset password for this user?</p>
        </q-card-section>
      </q-card>
    </q-form>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: { Modal },
  setup(props, { emit }) {
    const close = () => {
      emit('close')
    }

    const submit = () => {
      const data = {
        id: props?.data?.id
      }
      emit('submit', data)
    }

    return {
      close,
      submit
    }
  }
}
</script>
