<template>
  <PageHeader breadcrumb="Maintenance" title="User Maintenance" />
  <PageContent>
    <div class="mb-4">
      <Notif
        :visible="isResetPasswordSuccess"
        variant="success"
        :message="notifMessage"
        @close="onHideNotif"
      />
    </div>
    <Table
      :rows="listTableData"
      :columns="listTableHeader"
      :loading="loadingTable"
      :limit="limitPage"
      :page="pageNo"
      :total="totalCount"
      :filter="filter"
      serverSide
      @onRequest="onRequest"
    >
      <template v-slot:top-left>
        <q-btn
          color="primary"
          icon="person_add"
          label="Create User"
          rounded
          unelevated
          @click="openUserModal(false)"
        />
      </template>

      <template v-slot:top-right v-if="false">
        <q-input
          dense
          rounded
          outlined
          debounce="300"
          v-model="filter"
          placeholder="Search"
        >
          <template v-slot:append>
            <q-icon
              :name="filter ? 'close' : 'search'"
              :class="filter && 'cursor-pointer'"
              @click="onClearSearch"
            />
          </template>
        </q-input>
      </template>

      <template v-slot:body="props">
        <q-tr>
          <q-td>
            {{ props.row.id }}
          </q-td>
          <q-td>
            {{ props.row.email }}
          </q-td>
          <q-td>
            {{ props.row.lastName }}
          </q-td>
          <q-td>
            {{ props.row.firstName }}
          </q-td>
          <q-td>
            {{ props.row.role }}
          </q-td>
          <q-td>
            <q-badge :color="props.row.status === 'A' ? 'green' : 'red'">
              {{ props.row.status === 'A' ? 'Active' : 'Inactive' }}
            </q-badge>
          </q-td>
          <q-td class="text-right">
            <Button icon="more_vert" round flat>
              <q-menu>
                <q-list dense style="min-width: 200px" class="text-blue-10">
                  <q-item
                    clickable
                    v-close-popup
                    @click="openUserModal(true, props.row)"
                  >
                    <q-avatar icon="edit" size="lg" />
                    <q-item-section>Edit</q-item-section>
                  </q-item>
                  <q-item
                    clickable
                    v-close-popup
                    @click="openResetPasswordModal(props.row)"
                  >
                    <q-avatar icon="vpn_key" size="lg" />
                    <q-item-section>Reset Password</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </Button>
          </q-td>
        </q-tr>
      </template>
    </Table>
  </PageContent>

  <UserFormModal
    :show="userModal"
    :edit="isEditUserForm"
    :data="selectedData"
    :loading="loadingCreate"
    :success="isCreateSuccess"
    @close="closeUserModal"
    @submit="onSubmitUser"
  />

  <ResetPasswordModal
    :show="resetPasswordModal"
    :data="selectedData"
    @close="closeResetPasswordModal"
    @submit="onSubmitResetPassword"
  />
</template>

<script>
import { ref, watch, onBeforeMount } from 'vue'

import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent'
import UserFormModal from './components/user-maintenance/UserFormModal'
import ResetPasswordModal from './components/user-maintenance/ResetPasswordModal'
import Table from '@/components/Table'
import Button from '@/components/Button'
import Notif from '@/components/Notif'

import { Toast, fetchData } from '@/tools'

export default {
  name: 'UserMaintenance',
  components: {
    PageHeader,
    PageContent,
    UserFormModal,
    ResetPasswordModal,
    Table,
    Button,
    Notif
  },
  setup() {
    const { showToast } = Toast()
    const {
      data: dataList,
      error: errorList,
      loading: loadingList,
      get: getList
    } = fetchData()

    const {
      data: dataCreate,
      error: errorCreate,
      loading: loadingCreate,
      post: postCreate
    } = fetchData()

    const {
      data: dataUpdate,
      error: errorUpdate,
      loading: loadingUpdate,
      put: postUpdate
    } = fetchData()

    const {
      data: dataReset,
      error: errorReset,
      loading: loadingReset,
      post: postReset
    } = fetchData()

    const userModal = ref(false)
    const resetPasswordModal = ref(false)
    const filter = ref('')
    const loadingTable = ref(true)
    const isFiltered = ref(false)
    const limitPage = ref(10)
    const pageNo = ref(1)
    const totalCount = ref(0)
    const pagination = ref({
      page: pageNo.value,
      rowsPerPage: limitPage.value,
      rowsNumber: totalCount.value
    })
    const isCreateSuccess = ref(false)
    const isEditUserForm = ref(false)
    const selectedData = ref(null)
    const isResetPasswordSuccess = ref(false)
    const notifMessage = ref('')

    const listTableHeader = ref([
      {
        name: 'id',
        required: true,
        label: 'User ID',
        align: 'center',
        field: 'id',
        sortable: true
      },
      {
        name: 'email',
        required: true,
        label: 'Email',
        align: 'center',
        field: 'email',
        sortable: true
      },
      {
        name: 'lastName',
        align: 'center',
        label: 'Last Name',
        field: 'lastName',
        sortable: true
      },
      {
        name: 'firstName',
        align: 'center',
        label: 'First Name',
        field: 'firstName',
        sortable: true
      },
      { name: 'role', align: 'center', label: 'Role', field: 'role' },
      { name: 'status', align: 'center', label: 'Status', field: 'status' },
      { name: 'action', align: 'right', label: '' }
    ])

    const listTableData = ref([])

    const onClearSearch = () => {
      filter.value = ''
    }

    const openUserModal = (isEdit, data) => {
      userModal.value = true
      isEditUserForm.value = isEdit
      selectedData.value = isEdit ? data : null
    }

    const openResetPasswordModal = data => {
      resetPasswordModal.value = true
      selectedData.value = data
    }

    const closeUserModal = () => {
      userModal.value = false
      isEditUserForm.value = false
    }

    const closeResetPasswordModal = () => {
      resetPasswordModal.value = false
      selectedData.value = null
    }

    const onSubmitUser = data => {
      if (data) {
        if (isEditUserForm.value === true) {
          const payload = {
            user_info: {
              id: data?.id,
              first_name: data?.firstName,
              last_name: data?.lastName,
              role_id: data?.role,
              status: data?.status
            },
            store_access: data?.store
          }
          postUpdate('v1/user', payload)
        } else {
          const payload = {
            user_info: {
              email: data?.email,
              password: data?.password,
              first_name: data?.firstName,
              last_name: data?.lastName,
              role_id: data?.role,
              status: data?.status
            },
            store_access: data?.store
          }
          postCreate('v1/user', payload)
        }
      }
    }

    const onSubmitResetPassword = data => {
      if (data) {
        postReset(`v1/user/reset-password/${data?.id}`)
      }
    }

    const getUserList = () => {
      getList(`v1/user?page=${pageNo.value}&per_page=${limitPage.value}`)
    }

    const onRequest = data => {
      const { page, rowsPerPage } = data.pagination
      pageNo.value = page
      limitPage.value = rowsPerPage

      pagination.value.page = page
      pagination.value.rowsPerPage = rowsPerPage

      isFiltered.value = true
      getUserList()
    }

    const onHideNotif = () => {
      isResetPasswordSuccess.value = false
    }

    onBeforeMount(() => {
      getUserList()

      watch([dataList, errorList, loadingList], () => {
        if (loadingList.value) {
          if (isFiltered.value) {
            loadingTable.value = true
          } else {
            listTableData.value = []
          }
        } else {
          if (errorList?.value) {
            showToast(errorList?.value.errors[0].msg, 'danger')
          } else if (dataList?.value) {
            pagination.value.rowsNumber = dataList?.value?.total
            totalCount.value = dataList?.value?.total
            listTableData.value = dataList?.value?.data?.map(item => ({
              id: item?.id,
              email: item?.email,
              firstName: item?.first_name,
              lastName: item?.last_name,
              role: item?.role,
              roleID: item?.role_id,
              status: item?.status
            }))
          }

          isFiltered.value = false
          loadingTable.value = false
        }
      })

      watch([dataCreate, errorCreate, loadingCreate], () => {
        if (!loadingCreate.value) {
          if (errorCreate?.value) {
            isCreateSuccess.value = false
            showToast(errorCreate?.value.errors[0].msg, 'danger')
          } else if (dataCreate?.value) {
            if (dataCreate?.value) {
              getUserList()
              isCreateSuccess.value = true
              showToast('User created successfully!', 'success')
            }
          }
        }
      })

      watch([dataUpdate, errorUpdate, loadingUpdate], () => {
        if (!loadingUpdate.value) {
          if (errorUpdate?.value) {
            showToast(errorUpdate?.value.errors[0].msg, 'danger')
          } else if (dataUpdate?.value) {
            if (dataUpdate?.value) {
              getUserList()

              isCreateSuccess.value = true
              showToast('User updated successfully!', 'success')
            }
          }
        }
      })

      watch([dataReset, errorReset, loadingReset], () => {
        if (!loadingReset.value) {
          if (errorReset?.value) {
            showToast(errorReset?.value.errors[0].msg, 'danger')
          } else if (dataReset?.value) {
            if (dataReset?.value) {
              closeResetPasswordModal()
              isResetPasswordSuccess.value = true
              notifMessage.value = `Password successfully changed. New Password: ${dataReset?.value?.new_password}`
              showToast('Password reset successfully', 'success')
            }
          }
        }
      })
    })

    return {
      userModal,
      resetPasswordModal,
      filter,
      loadingTable,
      isFiltered,
      limitPage,
      pageNo,
      totalCount,
      pagination,
      isCreateSuccess,
      isEditUserForm,
      selectedData,
      isResetPasswordSuccess,
      notifMessage,
      listTableHeader,
      listTableData,
      loadingCreate,
      onClearSearch,
      openUserModal,
      openResetPasswordModal,
      closeUserModal,
      closeResetPasswordModal,
      onSubmitUser,
      onSubmitResetPassword,
      onRequest,
      onHideNotif
    }
  }
}
</script>

<style lang="scss">
.tableHeader th {
  font-weight: 900;
}
</style>
